import React from 'react';

const GameIcon = (props) => {

    let path;
    if (props.game === '1') {
        path = 'images/game1-icon.png'
    } else if (props.game === '2') {
        path = 'images/game2-icon.png'
    } else if (props.game === '3') {
        path = 'images/game3-icon.png'
    } else if (props.game === '4') {
        path = 'images/game4-icon.png'
    } else if (props.game === '5') {
        path = 'images/game5-icon.png'
    } else if (props.game === '6') {
        path = 'images/game6-icon.png'
    } else if (props.game === '7') {
        path = 'images/game7-icon.png'
    }

    if (props.status === 'done') {
        path = 'images/done-icon.png'
    }

    return (
            <img width="46" src={path} className="menu-item__icon" alt=""/>
    );
};

export default GameIcon;