import React from 'react';

const Footer = () => {
    return (
        <div className="labels-block">
            <a href="https://vk.com/teamfighttactics" target="_blank" className="labels-block__label-icon label-icon">
                <img width="84" height='28' src="images/riot-games-icon.svg" alt=""/>
            </a>
            <a href="https://vk.com/teamfighttactics" target="_blank" className="labels-block__label-icon label-icon">
                <img width="36" height='40' src="images/rating-pic.png" alt=""/>
            </a>
            <a href="https://vk.com/teamfighttactics" target="_blank" className="labels-block__label-icon label-icon">
                <img width="91" height='32' src="images/teamfight-icon.png" alt=""/>
            </a>
        </div>
    );
};

export default Footer;