import React from 'react';
import Footer from "../../components/Footer/Footer";
import {useDispatch} from "react-redux";
import {setActivePanel, setCurrentGame} from "../../store/mainReducer";

const Game7Choice = () => {
    const dispatch = useDispatch()
    function choice_jinx () {
        dispatch(setCurrentGame(7))
        dispatch(setActivePanel('result'))
    }

    function choice_vi () {
        dispatch(setCurrentGame(8))
        dispatch(setActivePanel('result'))
    }
    return (
        <div className="container task-page ">
            <div className="main-block container__middle-block">
                <div className="video-task">
                    <p className="caption caption_size_normal video-task__caption">А теперь выбери, кто из двух сестер
                        тебе больше импонирует?</p>
                    <div className="video-task__content">
                        <div className="video-task__fighter">
                            <div className="video-task__fighter-image video-task__fighter-image_order_1">
                                <img src="images/fighter-pic1.png" width="138" alt=""/>
                            </div>
                            <button onClick={choice_jinx} className="button button_color_gradient video-task__button">Джинкс</button>
                        </div>
                        <img className="video-task__vs" src="images/versus-pic.png" width="191" alt=""/>
                        <div className="video-task__fighter">
                            <div className="video-task__fighter-image video-task__fighter-image_order_2">
                                <img src="images/fighter-pic2.png" width="138" alt=""/>
                            </div>
                            <button onClick={choice_vi} className="button button_color_gradient video-task__button">Вай</button>
                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default Game7Choice;
