import React from 'react';
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Game4 = () => {

    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const go = () => {
        dispatch(setActivePanel('result'))
    }
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='aboutgame4'/>
                <div className="task-page__header">
                    <img src="images/game4g-icon.png" width="60" alt=""/>
                    <p className="caption caption_size_small">Настроение<br/>Векс</p>
                </div>
                <div className="avatar-block">
                    <div className="avatar-block__pic">
                        <img src={`https://tft.techbot24.ru/static/avatars/photo_${user.vk_id}.png`} loading="lazy" decoding="async" alt=""/>
                    </div>
                    <div className="avatar-block__content">
                        <p className="text text_size_normal avatar-block__text">Отныне никакой радости. Лишь тоска, уныние и тлен. Идеально!</p>
                        <a href={`https://tft.techbot24.ru/static/avatars/photo_${user.vk_id}.png`} target="_blank" download={true} className="button button_color_gradient avatar-block__button">Скачать аватарку</a>
                        <button onClick={go} className="button button_color_transparent avatar-block__button">
                            <p>
                                <span>продолжить</span>
                            </p>
                        </button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default Game4;
