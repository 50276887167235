import React, {useEffect, useReducer, useRef, useState} from 'react';
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Game3 = () => {

    const dispatch = useDispatch()
    const [melody, setMelody] = useState({
        path: '',
        answer1: ['', null], answer2: ['', null], answer3: ['', null]
    })

    const myRef = useRef()


    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getMelody() {
            const num = getRandomInt(1, 8)
            let audio = audio_data[num]
            setMelody(audio)
        }

        getMelody()
    }, [])

    function pause() {
        myRef.current.pause()
    }

    function play() {
        myRef.current.play()
    }

    function repeat() {
        myRef.current.load()
        myRef.current.play()
    }

    const audio_data = {
        1: {
            path: 'audio/warriors.mp3',
            answer1: ['TELLE - I’ll Find a Way', false],
            answer2: ['Cailin Russo - Phoenix', false],
            answer3: ['2WEI - Warriors', true]
        },
        2: {
            path: 'audio/never-die.mp3',
            answer1: ['Against the Current - Legends Never Die', true],
            answer2: ['Cailin Russo - Phoenix', false],
            answer3: ['PVRIS - Burn It All Down', false]
        },
        3: {
            path: 'audio/get-jinxed.mp3',
            answer1: ['The Curse of the Sad Mummy', false],
            answer2: ['Get Jinxed', true],
            answer3: ['The Weapon of the Faithful', false]
        },
        4: {
            path: 'audio/more.mp3',
            answer1: ['K/DA - POP/Stars', false],
            answer2: ['K/DA - More', true],
            answer3: ['K/DA - Baddest', false]
        },
        5: {
            path: 'audio/take-over.mp3',
            answer1: ['MAX - Take Over', true],
            answer2: ['TELLE - I’ll Find a Way', false],
            answer3: ['Cal Scruby - Run It', false]
        },
        6: {
            path: 'audio/lightbringer.mp3',
            answer1: ['Pentakill - Mortal Reminder', false],
            answer2: ['Pentakill - Lightbringer', true],
            answer3: ['Pentakill - Lost Chapter', false]
        },
        7: {
            path: 'audio/burn-down.mp3',
            answer1: ['Nicki Taylor - Worlds Collide', false],
            answer2: ['2WEI - Warriors', false],
            answer3: ['PVRIS - Burn It All Down', true]
        },
    }


    const go = (e) => {
        let btn = Number(e.currentTarget.id)
        let isRight;
        if (btn === 1) {
            isRight = melody.answer1[1]
        } else if (btn === 2) {
            isRight = melody.answer2[1]
        } else {
            isRight = melody.answer3[1]
        }
        if (isRight) {
            dispatch(setActivePanel('result'))
        } else {
            dispatch(setActivePanel('failed'))
        }
    }


    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='aboutgame3'/>
                <div className="task-page__header">
                    <img src="images/game3g-icon.png" width="45" height="65" alt=""/>
                    <p className="caption caption_size_small">Отвязная<br/>вечеринка</p>
                </div>
                <div className="audio-wrapper task-page__audio-wrapper">
                    <button onClick={pause} className="audio-wrapper__button audio-wrapper__button_type_pause">
                        <img width="40" src="images/pause-icon.png" alt=""/>
                    </button>
                    <button onClick={play} className="audio-wrapper__button audio-wrapper__button_type_play">
                        <img width="85" src="images/play-icon.png" alt=""/>
                    </button>
                    <button onClick={repeat} className="audio-wrapper__button audio-wrapper__button_type_replay">
                        <img width="40" src="images/replay-icon.png" alt=""/>
                    </button>
                    <audio ref={myRef}
                           className="audio-wrapper__sample"
                           src={melody.path}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                </div>
                <div className="task-page__button-grid button-grid">
                    <button id='1' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button">
                        {melody.answer1[0]}
                    </button>
                    <button id='2' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button">
                        {melody.answer2[0]}
                    </button>
                    <button id='3' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button_last-button">
                        {melody.answer3[0]}
                    </button>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default Game3;