import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, setCurrentGame} from "../../store/mainReducer";
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";

const AboutGame4 = () => {

    const dispatch = useDispatch()
    dispatch(setCurrentGame(4))
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='menu'/>
                <div className="menu-item card-page__menu-item menu-item_active menu-item_size_big"
                     style={{backgroundImage: "url(images/menu-item-bg-active.png)"}}>
                    <button className="menu-item__button">
                        <img width="46" src="images/game4-icon.png" className="menu-item__icon" alt=""/>
                        <p className="menu-item__caption menu-item__caption_size_big">
                           Настроение Векс
                        </p>
                    </button>
                </div>
                <p className="text text_size_normal text_align_center main-block__text">Тусовщики опять смеются и
                    веселятся... А мир вообще-то полон печали! Грустные ребята этого города, давайте напомним о
                    себе и покажем им, как нас на самом деле много.</p>
                <button onClick={()=>dispatch(setActivePanel('game4preload'))} className="main-block__button button button_color_gradient">Напомнить!</button>
            </div>
            <Footer/>
        </div>
    );
};

export default AboutGame4;