import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";
import GameIcon from "../GameIcon/GameIcon";
import TitleGameHeader from "../TitleGameHeader/TitleGameHeader";

const ClearButton = (props) => {

    const dispatch = useDispatch()

    function go(id) {
        dispatch(setActivePanel(`aboutgame${id}`))
    }

    return (
        <button onClick={()=>go(props.game)} className="menu-item__button">
            <GameIcon game={props.game} status={props.status}/>
            <TitleGameHeader game={props.game}/>
        </button>
    );
};

export default ClearButton;