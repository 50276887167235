import React from 'react';
import LookItem from "../LookItem/LookItem";

const LookItemList = () => {
    return (
        <>
            <LookItem id={1}/>
            <LookItem id={2}/>
            <LookItem id={3}/>
            <LookItem id={4}/>
        </>
    );
};

export default LookItemList;