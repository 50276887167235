import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";
import Footer from "../../components/Footer/Footer";

const Game6Success = () => {

    const dispatch = useDispatch()
    const [item, setItem] = useState({name:'', desc:'', path:''})
    const go = () => {
        dispatch(setActivePanel('result'))
    }

    useEffect(() => {
        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }
        function getItem() {
            const num = getRandomInt(1, 14)
            let item = res_data[num]
            setItem(item)
        }
        getItem()
    }, [])

    const res_data = {
        1: {name: 'Посох архангела', desc: 'Переработан: теперь даем доп. силу умений каждые 4 сек.', path: 'images/g6-posoh-arhangela.png'},
        2: {name: 'Терновый жилет', desc: 'Теперь дает доп. броню и сниженный урон по площади', path: 'images/g6-ternoviy-gilet.png'},
        3: {name: 'Клинок смерти', desc: 'Переработан: теперь дает доп. силу атаки', path: 'images/g6-klinok-smerti.png'},
        4: {name: 'Коготь дракона', desc: 'Изменен: уменьшены перезарядка и коэф. урона от макс. здоровья', path: 'images/g6-drakon.png'},
        5: {name: 'Хекстековый штык', desc: 'Переработан: больше не дает щит, а лечит союзника с наим. здоровьем', path: 'images/g6-shtik.png'},
        6: {name: 'Драгоценная перчатка', desc: 'Теперь допольнительно дает силу умений', path: 'images/g6-perchatka.png'},
        7: {name: 'Стремительное серебро', desc: 'Теперь допольнительно дает скорость атаки', path: 'images/g6-serebro.png'},
        8: {name: 'Ураган Рунаан', desc: 'Теперь дает доп. силу атаки, но не активирует эффекты при попадании', path: 'images/g6-runaan.png'},
        9: {name: 'Заточка Статикка', desc: 'Теперь допольнительно дает скорость атаки', path: 'images/g6-zatochka-staticca.png'},
        10: {name: 'Корона тактика', desc: 'Сила природы получила новое название и внешний вид', path: 'images/g6-korona-tactika.png'},
        11: {name: 'Решимость тинана', desc: 'Получила слегка обновленный внешний вид', path: 'images/g6-titan.png'},
        12: {name: 'Вестник Зика', desc: 'Получил новый внешний вид', path: 'images/g6-vestnik-zika.png'},
        13: {name: 'Ионийская искра', desc: 'Получила новый внешний вид', path: 'images/g6-iskra.png'},
    }
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <div className="task-page__header task-page__header_visible_xs">
                    <img src="images/game6g-icon.png" width="60" alt=""/>
                    <p className="caption caption_size_small">Есть<br/>дело</p>
                </div>
                <div className="game-success-block">
                    <div className="game-success-block__pic game-success-block__pic_visible_xl">
                        <img className="game-success-block__cat-image" src={item.path} alt=""/>
                    </div>
                    <div className="game-success-block__content">
                        <p className="caption caption_size_big game-success-block__caption">Получилось!</p>
                        <p className="text text_size_normal game-success-block__text">Раздался заветный щелчок, дверь
                            сейфа приоткрылась. Внутри ты обнаружил...</p>
                        <div className="prize-block game-success-block__prize-block">
                            <p className="prize-block__caption caption caption_size_small">{item.name}</p>
                            <p className="prize-block__text text text_size_normal">{item.desc}</p>
                        </div>
                        <div className="game-success-block__pic game-success-block__pic_visible_xs">
                            <img className="game-success-block__cat-image" src={item.path} alt=""/>
                        </div>
                        <button onClick={go} className="button button_color_transparent game-success-block__button">
                            <p><span>продолжить</span></p>
                        </button>
                    </div>
                    <img className="puzzle-wrapper__character-second" src="images/tank-character.png" width="247" alt=""/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Game6Success;
