import React from 'react';

const GiftText = (props) => {
    return (
        <>
            <p className="text text_size_normal text_align_center main-block__text">
                <strong style={{fontSize:'25px', color:'#e3ed59'}}>{props.txt[0]}</strong><br/>{props.txt[1]}

            </p>
        </>
    );
};

export default GiftText;