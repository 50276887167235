import React from 'react';
import {setActivePanel} from "../store/mainReducer";
import Footer from "../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";

const Failed = () => {
    const curGame = useSelector(state => state.main.currentGame)
    const dispatch = useDispatch()
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <p className="caption caption_size_xl caption_align_center main-block__caption">Ответ неверный</p>
                <p className="text text_size_normal text_align_center main-block__text">Попробуй пройти задание еще раз!</p>
                <button onClick={()=>dispatch(setActivePanel(`game${curGame}`))} className="main-block__button button button_color_gradient">Повторить</button>
            </div>
            <Footer/>
        </div>
    );
};

export default Failed;