import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name:'main',
    initialState: {
        activePanel: 'init',
        currentGame: null,
        user: null,
        userExist: null,
        nick_name:'',
        game_version: 1
    },
    reducers:{
        setActivePanel(state, action) {
               state.activePanel = action.payload
        },
        setCurrentGame(state,action) {
            state.currentGame = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        setUserExist(state,action){
            state.userExist = action.payload
        },
        setNickName(state, action){
            state.nick_name = action.payload
        },
        setGameVersion(state, action) {
            state.game_version = action.payload
        }
    }
})

export default mainSlice.reducer
export const {setActivePanel,setUser, setCurrentGame, setUserExist, setNickName, setGameVersion} = mainSlice.actions
