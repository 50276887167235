import React from 'react';
import Footer from "../../components/Footer/Footer";
import Arrow from "../../components/Arrow/Arrow";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Game5Success = () => {
    const dispatch = useDispatch()
    const game_version = useSelector(state => state.main.game_version)
    const go = () => {
        dispatch(setActivePanel('result'))
    }
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url={'game5'}/>
                <div className="puzzle-success-block">
                    <p className="caption caption_size_big puzzle-success-block__caption puzzle-success-block__caption_visible_xs">Получилось!</p>
                    <div className="puzzle-success-block__pic">
                        <img width="100%" src={`images/puzzle${game_version}-success.jpg`} alt=""/>
                    </div>
                    <div className="puzzle-success-block__content">
                        <p className="caption caption_size_big puzzle-success-block__caption puzzle-success-block__caption_visible_lg">Получилось!</p>
                        <button onClick={go} className="button button_color_gradient puzzle-success-block__button">Продолжить
                        </button>
                    </div>
                    <img className="puzzle-wrapper__character-second" src="images/tank-character.png"
                         width="247" alt=""/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Game5Success;