import React from 'react';
import './Header.css'

const Header = (props) => {
        return (
        <>
        <p className={`caption caption_size_normal ${props.block}-block__caption`}>{props.children}</p>
        </>
    );
};

export default Header
