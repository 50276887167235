import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";
import Footer from "../../components/Footer/Footer";
import Spinner from "../../components/Spinner/Spinner";
import ServerConnect from "../../service";

const Game4Preload = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    useEffect(()=>{
        ServerConnect.sendGet('/api/user/ava/'+user.vk_id)
        setTimeout(()=>{
            dispatch(setActivePanel('game4'))
        },2500)
    },[])
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <div className="task-page__header">
                    <img src="images/game4g-icon.png" width="60" alt=""/>
                    <p className="caption caption_size_small">Настроение<br/>Векс</p>
                </div>
                <div className="loader-wrapper">
                    <Spinner/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Game4Preload;