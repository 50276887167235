import React from 'react';

const Spinner = () => {
    return (
        <div className="loader-block">
            <img src="images/loader-icon.png" alt=""/>
                <p className="text text_size_normal text_align_center">Ложные эмоции покидают тебя...</p>
        </div>
    );
};

export default Spinner;