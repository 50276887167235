import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, setCurrentGame} from "../../store/mainReducer";
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";

const AboutGame2 = () => {

    const dispatch = useDispatch()
    dispatch(setCurrentGame(2))
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='menu'/>
                <div className="menu-item card-page__menu-item menu-item_active menu-item_size_big"
                     style={{backgroundImage: "url(images/menu-item-bg-active.png)"}}>
                    <button className="menu-item__button">
                        <img width="46" src="images/game2-icon.png" className="menu-item__icon" alt=""/>
                        <p className="menu-item__caption menu-item__caption_size_big">
                           Помощь академии
                        </p>
                    </button>
                </div>
                <p className="text text_size_normal text_align_center main-block__text">Великие умы академии
                    столкнулись со сложной задачей. Помогите нам разгрызть гранит науки и получите достойное
                    своего гения вознаграждение.</p>
                <button onClick={()=>dispatch(setActivePanel('game2'))} className="main-block__button button button_color_gradient">Помочь!</button>
            </div>
            <Footer/>
        </div>
    );
};

export default AboutGame2;