import React, {useEffect, useState} from 'react';
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Game2 = () => {
    const dispatch = useDispatch()
    const [question, setQuestion] = useState({text:'', answer1:['',null], answer2:['',null], answer3:['',null]})


    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getQuestion() {
            const num = getRandomInt(1, 10)
            let quest = data[num]
            setQuestion(quest)
        }
        getQuestion()
    }, [])


    const data = {
        1: {
            text: 'Мы больше и сильнее других, но занимаем по два места в команде. Кто мы?',
            answer1: ['Колоссы', true],
            answer2: ['Телохранители', false],
            answer3: ['Громилы', false]
        },
        2: {
            text: 'Две атаки вместо одной? Ничего себе! Кто же владеет такой способностью?',
            answer1: ['Задиры', false],
            answer2: ['Дуплеты', true],
            answer3: ['Убийцы', false]
        },
        3: {
            text: 'Эти бронированные чемпионы провоцируют ближайших противников. О ком идет речь?',
            answer1: ['Заступники', false],
            answer2: ['Задиры', false],
            answer3: ['Телохранители', true]
        },
        4: {
            text: 'Сражаются в тылу врага и умеют критовать способностями! Кто же они?',
            answer1: ['Имперцы', false],
            answer2: ['Заступники', false],
            answer3: ['Убийцы', true]
        },
        5: {
            text: 'Две разные формы в зависимости от положения на арене. Кто одарен такой способностью?',
            answer1: ['Гении', true],
            answer2: ['Изобретатели', false],
            answer3: ['Механические', false]
        },
        6: {
            text: 'Мы очень любим строить роботов! Угадай, кто мы?',
            answer1: ['Химтековые', false],
            answer2: ['Изобретатели', true],
            answer3: ['Механические', false]
        },
        7: {
            text: 'Какая особенность будет дарить тебе бесплатного чемпиона после каждого боя?',
            answer1: ['Дуплет', false],
            answer2: ['Йордл', true],
            answer3: ['Миротворец', false]
        },
        8: {
            text: 'Настолько неординарные, что получают разные бонусы в каждой игре!',
            answer1: ['Тусовщики', false],
            answer2: ['Чародеи', false],
            answer3: ['Мутанты', true]
        },
        9: {
            text: 'Маны много не бывает! Кто восстанавливает ману союзникам каждые 2 секунды?',
            answer1: ['Ученые', true],
            answer2: ['Адепты магии', false],
            answer3: ['Чародеи', false]
        },
        10: {
            text: 'Увеличиваем силу умений для всей команды. Кто мы такие?',
            answer1: ['Адепты магии', true],
            answer2: ['Чародеи', false],
            answer3: ['Миротворцы', false]
        }
    }

    const go = (e) => {
            let btn = Number(e.currentTarget.id)
            let isRight;
            if (btn === 1) {
                isRight = question.answer1[1]
            } else if (btn === 2) {
                isRight = question.answer2[1]
            } else {
                isRight = question.answer3[1]
            }
            if (isRight) {
                dispatch(setActivePanel('result'))
            } else {
                dispatch(setActivePanel('failed'))
        }
    }
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='aboutgame2'/>
                <div className="task-page__header">
                    <img src="images/game2g-icon.png" width="60" alt=""/>
                    <p className="caption caption_size_small">Помощь<br/>Академии</p>
                </div>

                <p className="text text_size_normal text_align_center main-block__text">{question.text}</p>
                <div className="task-page__button-grid button-grid">
                    <button id='1' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button">
                        {question.answer1[0]}
                    </button>
                    <button id='2' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button">
                        {question.answer2[0]}
                    </button>
                    <button id='3' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button_last-button">
                        {question.answer3[0]}
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Game2;