import React, {useEffect, useState} from 'react';
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";
import LookItemList from "../../components/LookItemList/LookItemList";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";


const Game6 = () => {

    const dispatch = useDispatch()
    const [combination, setCombination] = useState([2,1,3,4])
    const [userComb, setUserComb] = useState([])
    const [trigger, setTrigger] = useState([false,false,false,false])
    
    useEffect(()=>{
        const comb = [[1,2,3,4],[1,2,4,3],[1,3,2,4],[1,3,4,2],[1,4,2,3],[1,4,3,2],
            [2,1,3,4],[2,1,4,3],[2,3,1,4],[2,3,4,1],[2,4,1,3],[2,4,3,1],
            [3,1,2,4],[3,1,4,2],[3,2,1,4],[3,2,4,1],[3,4,1,2],[3,4,2,1],
            [4,1,2,3],[4,1,3,2],[4,2,1,3],[4,2,3,1],[4,3,1,2],[4,3,2,1],
        ]
        function choose(choices) {
          let index = Math.floor(Math.random() * choices.length);
          setCombination(choices[index])}

        choose(comb);
    },[])

    function onTrigger (e) {
        let id = Number(e.currentTarget.id)
        setTrigger([...trigger.slice(0,id-1),true,...trigger.slice(id)])
        setUserComb([...userComb, id])
    }

    if (userComb !== []) {
        for (let i = 0; i < userComb.length; i++) {
            if (userComb[i] !== combination[i]){
                setTrigger([false,false,false,false])
                setUserComb([])
            }
        }
        if (userComb.toString()==combination.toString()) {
            dispatch(setActivePanel('game6success'))
        }
    }
    let gameWrapper = ''
    for (let i=0; i<trigger.length;i++) {
        if (trigger[i]) {
        gameWrapper+=`game-wrapper_active_${i+1} `
        }
    }
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='aboutgame6'/>
                <div className="task-page__header task-page__header_visible_xs">
                    <img src="images/game6g-icon.png" width="60" alt=""/>
                    <p className="caption caption_size_small">Есть<br/>дело</p>
                </div>
                <div className="game-block">
                    <div
                        className={`game-block__game-wrapper game-wrapper ${gameWrapper}`}>
                        <img className="game-block__key" src="images/game-key.png" width="293" alt=""/>
                        <LookItemList/>
                    </div>
                    <div className="game-block__content">
                        <p className="text text_size_normal game-block__text">Укажи номер штифа, чтобы нажать на него:</p>
                        <div className="game-triggers">
                            <button id='1' disabled={trigger[0]} onClick={onTrigger} className={`game-trigger ${(trigger[0]===true) ? 'game-trigger_active' : ''}`}>
                                1
                            </button>
                            <button id='2' disabled={trigger[1]} onClick={onTrigger} className={`game-trigger ${(trigger[1]===true) ? 'game-trigger_active' : ''}`}>
                                2
                            </button>
                            <button id='3' disabled={trigger[2]} onClick={onTrigger} className={`game-trigger ${(trigger[2]===true) ? 'game-trigger_active' : ''}`}>
                                3
                            </button>
                            <button id='4' disabled={trigger[3]} onClick={onTrigger} className={`game-trigger ${(trigger[3]===true) ? 'game-trigger_active' : ''}`}>
                                4
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Game6;