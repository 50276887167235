import React from 'react';

const LookItem = ({id}) => {
    return (
        <div className={`game-item game-item_number_${id}`}>
            <div className="game-item__spring">
                <img src="images/game-spring.png" alt=""/>
            </div>
            <div className="game-item__number">{id}</div>
        </div>
    );
};

export default LookItem;