import React from 'react';
import MenuItem from "../MenuItem/MenuItem";
import {useSelector} from "react-redux";

const MenuItemList = () => {

    const user = useSelector(state=>state.main.user)
    const games = {
        1:'active',
        2:'active',
        3:'active',
        4:'active',
        5:'active',
        6:'active',
        7:'active',
    }

    let content_list = [];

        for (let i = 1; i < 8; i++) {
            let status;
            if (games[i] === 'disabled') {
                status = 'disabled'
            } else if (games[i] === 'active' && user[`state${i}`] === true) {
                status = 'done'
            } else {
                status = 'active'
            }
            content_list.push(<MenuItem game={String(i)} block='menu' status={status} key={i}/>)
        }


    return (
        <div className="menu-block__list">
            {content_list}
        </div>
    );
};

export default MenuItemList;