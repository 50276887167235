import React from 'react';
import ClearButton from "../ClearButton/ClearButton";

const MenuItem = (props) => {

    const disabled_img = 'images/menu-item-bg-disabled.png'
    const active_img = 'images/menu-item-bg-active.png'
    const done_img = 'images/menu-item-bg-done.png'

    let path;
    let content = []

    if (props.status === 'active') {
        path = active_img
    } else if (props.status === 'disabled') {
        path = disabled_img
    } else {
        path = done_img
    }

    if (props.status === 'active' ||  props.status === 'done') {
        content.push(<ClearButton game={props.game} key={props.game} status={props.status}/>)
    }

    return (
        <div className={`menu-item ${props.block}-item_${props.status}`} style={{backgroundImage:`url(${path})`}} key={props.game}>
            {content}
        </div>
    );
};

export default MenuItem
