import React from 'react';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const AboutApp = () => {

    const dispatch = useDispatch()

    return (
        <div className='container'>
            <div className="main-block container__middle-block">
                <Header block='main'>Весточка<br/>С дальних земель</Header>
                <p className="text text_size_normal main-block__text">До Конвергенции дошли слухи о
                    «Конкурсе хекстек-гаджетов» в городе Пилтовере. Будучи изобретательным тактиком,
                    ты решаешь в нем поучаствовать. Прибыв в город, ты натыкаешься на доску объявлений,
                    где разные фракции Пилтовера и его теневого района Зауна оставляют заказы.
                    За их выполнение платят местной альтернативной валютой - аугментациями,
                    которые пригодятся при создании твоего гаджета.</p>
                <button onClick={()=>dispatch(setActivePanel('login'))} className="main-block__button button button_color_gradient">
                    Продолжить
                </button>
            </div>
            <Footer/>
        </div>
    );
};

export default AboutApp;