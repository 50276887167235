import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Init = () => {

    const [progress, setProgress] = useState('progress-10');
    const dispatch = useDispatch()
    const userExist = useSelector(state=>state.main.userExist)


    useEffect(()=>{
        if (userExist !== null) {
            setTimeout(()=>{
                goNext();
            },3000)
        }
    },[userExist])

    useEffect(()=>{
        setProgress('progress-100')
    },[progress])

    function goNext() {
        if (userExist) {dispatch(setActivePanel('menu'))
        } else {
            dispatch(setActivePanel('about'))
        }
    }

    return (
        <div className="loading-page">
            <div className="progress-bar loading-page__progress-bar">
                <div className={"progress-bar__active-line "+ progress}/>
            </div>
        </div> )
};

export default Init
