import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, setCurrentGame} from "../../store/mainReducer";
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";

const AboutGame3 = () => {

    const dispatch = useDispatch()
    dispatch(setCurrentGame(3))
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='menu'/>
                <div className="menu-item card-page__menu-item menu-item_active menu-item_size_big"
                     style={{backgroundImage: "url(images/menu-item-bg-active.png)"}}>
                    <button className="menu-item__button">
                        <img width="46" src="images/game3-icon.png" className="menu-item__icon" alt=""/>
                        <p className="menu-item__caption menu-item__caption_size_big">
                            Отвязная вечеринка
                        </p>
                    </button>
                </div>
                <p className="text text_size_normal text_align_center main-block__text">Тусовщики ищут лучшего
                    диджея! Нам подойдет только тот, кто по-настоящему разбирается в музыке, поэтому вам нужно
                    будет пройти тест с угадыванием названий песен.</p>
                <button onClick={()=>dispatch(setActivePanel('game3'))} className="main-block__button button button_color_gradient">Играть!</button>
            </div>
            <Footer/>
        </div>
    );
};

export default AboutGame3;