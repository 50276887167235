import React from 'react';
import Footer from "../../components/Footer/Footer";
import Arrow from "../../components/Arrow/Arrow";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Game7Video = () => {
    const dispatch = useDispatch()

    return (
        <div className="container task-page video-page">
            <div className="main-block container__middle-block">
                <Arrow url={'aboutgame7'}/>
                <div className="task-page__header">
                    <img src="images/game7g-icon.png" width="60" alt=""/>
                        <p className="caption caption_size_small">Вражда<br/>сестер</p>
                </div>
                <div className="video-page__video-wrapper video-wrapper">
                    <iframe src="https://vk.com/video_ext.php?oid=-49423435&id=456245920&hash=08680ef747bcbb2d&hd=2"
                            width="100%" allow="autoplay; encrypted-media; picture-in-picture;"
                            frameBorder="0"></iframe>
                </div>
                <button onClick={()=>dispatch(setActivePanel('game7'))} className="button button_color_gradient video-page__button">Продолжить</button>
            </div>
            <Footer/>
        </div>
    );
};

export default Game7Video;