import React from 'react';

const TitleGameHeader = (props) => {

    let header;
    if (props.game === '1') {
        header = 'Рекруты наемников'
    } else if (props.game === '2') {
        header = 'Помощь академии'
    } else if (props.game === '3') {
        header = 'Отвязная вечеринка'
    } else if (props.game === '4') {
        header = 'Настроение Векс'
    } else if (props.game === '5') {
        header = 'Древний механизм'
    } else if (props.game === '6') {
        header = 'Есть дело'
    } else if (props.game === '7') {
        header = 'Вражда сестер'
    }

    return (
            <p className="menu-item__caption">
                {header}
            </p>
    );
};

export default TitleGameHeader;