import React, {useEffect} from 'react';
import Footer from "../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setUser} from "../store/mainReducer";
import ServerConnect from "../service";
import bridge from "@vkontakte/vk-bridge";
import GiftText from "../components/TextGift/GiftText";

const Result = () => {

    useEffect(()=>{

        sendResult()
    },[])

    const dispatch = useDispatch()
    const currentGame = useSelector(state=>state.main.currentGame)
    const user = useSelector(state =>state.main.user)

    function sendResult () {
        let state = ''
        if (currentGame === 8) {
          state = 'state7'
        } else {
            state = `state${currentGame}`
        }
        let context = {[state]: true}
        ServerConnect.sendPut('/api/user/'+user.vk_id, context)
            .then(data => dispatch(setUser(data)))
    }

    const res_data = {
        1:{'header':'спасибо за отклик!',
            'txt':'Возьми плакат и размести его на своей странице. Всех агитаторов ждет награда:',
            'gift':['ОТВОД ГЛАЗ','+ Доп. тренировочные манекены'],
            'msg': `Внимание, объявляется набор рекрутов! 
            Требуются отважные, могучие и непоколебимые бойцы 💪🏻

            Помоги наемникам и ты! Переходи в приложение, выполняй задания фракций и получай призы в Teamfight Tactics: яйца маленьких легенд и пропуски для нового сета!

            ➡️ vk.com/app7978368

            И приготовься тащить в новом наборе TFT: «Чудеса прогресса», который выходит уже скоро.`
        },
        2:{'header':'вот это познания!',
            'txt':'Возьми эту аугментацию в качестве благодарности:',
            'gift':['МЕДАЛЬ АКАДЕМИИ','+ Эмблема академика'],
            'msg': 'Я прошел тест академиков на знание грядущего набора 🤓\n' +
                '\n' +
                'А ты сумеешь дать правильный ответ? Переходи в приложение, выполняй задания фракций и получай призы в Teamfight Tactics: яйца маленьких легенд и пропуски для нового сета!\n' +
                '\n' +
                '➡️ vk.com/app7978368\n' +
                '\n' +
                'Новый набор TFT: «Чудеса прогресса» выходит уже скоро. Пора вернуться в игру!'
        },
        3:{'header':'а у тебя есть слух!',
            'txt':'Ты достойный претендент, держи свою награду:',
            'gift':['ДУЭТ','Тусовщики отсвещают на 1 ячейку больше'],
            'msg': 'Тусовщики уже оценили мои музыкальные познания 🎶\n' +
                '\n' +
                'Теперь твой черед! Переходи в приложение, выполняй задания фракций и получай призы в Teamfight Tactics: яйца маленьких легенд и пропуски для нового сета!\n' +
                '\n' +
                '➡️ vk.com/app7978368\n' +
                '\n' +
                'Уже скоро выход нового сета TFT: «Чудеса прогресса»!'
        },
        4:{'header':'спасибо...',
            'txt':'Не понимаю я всех этих конкурсов, но у меня есть кое-что для тебя:',
            'gift':['СЕРДЦЕ АДЕПТА МАГИИ','+1 к счетчику адептов магии'],
            'msg':'Как же я понимаю Векс... 😞\n' +
                '\n' +
                'А ты разделишь с ней ее печаль? Переходи в приложение, выполняй задания фракций и получай призы в Teamfight Tactics: яйца маленьких легенд и пропуски для нового сета!\n' +
                '\n' +
                '➡️ vk.com/app7978368\n' +
                '\n' +
                '«Чудеса прогресса» приближаются, 3-го ноября TFT вновь преобразится!'
        },
        5:{'header':'спасибо!',
            'txt':'Мы восхищены твоей смекалкой! Вот твоя аугментация:',
            'gift':['ДРЕВНИЕ АРХИВЫ','+ копия Книги особенностей'],
            'msg':'В головоломках мне нет равных 🧠\n' +
                '\n' +
                'Но ты тоже можешь попробовать выполнить этот заказ механических. Переходи в приложение, выполняй задания фракций и получай призы в Teamfight Tactics: яйца маленьких легенд и пропуски для нового сета!\n' +
                '\n' +
                '➡️ vk.com/app7978368\n' +
                '\n' +
                'Новый набор TFT: «Чудеса прогресса» уже вышел. Новые механики, особенности, маленькие легенды, арены и многое другое ждут тебя в игре!'
        },
        6:{'header':'мы обогатимся!',
            'txt':'Но и твои навыки должны быть вознаграждены, так что вот твоя плата:',
            'gift': ['ТОРГОВЫЙ СЕКТОР','Бесплатное обновление магазина каждый раунд'],
            'msg':'Взлом? Какой взлом? Я ничего не видел(а)! 😳\n' +
                '\n' +
                'Возможно, преступники ищут сообщника. Переходи в приложение, выполняй задания фракций и получи возможность выиграть яйцо маленькой легенды и совершенно нового чемпиончика в Teamfight Tactics!\n' +
                '\n' +
                '➡️ vk.com/app7978368\n' +
                '\n' +
                'Новый набор TFT: «Чудеса прогресса» уже вышел. Новые механики, особенности, маленькие легенды, арены и многое другое ждут тебя в игре!'
        },
        7:{'header':'поздравляем!',
            'txt': 'Ты определенно заслужил это:',
            'gift':['ВЫГОДНОЕ ПРЕДЛОЖЕНИЕ','+ХР при обновлении магазина'],
            'msg':'Безбашенная хулиганка Джинкс – вот мой выбор. Да будет хаос! 💥\n' +
                '\n' +
                'А кто из враждующих сестер симпатизирует тебе? Переходи в приложение, выполняй задания фракций и получи возможность выиграть яйцо маленькой легенды и совершенно нового чемпиончика в Teamfight Tactics!\n' +
                '\n' +
                '➡️ vk.com/app7978368\n' +
                '\n' +
                'Новый набор TFT: «Чудеса прогресса» и сериал «Аркейн» по вселенной League of Legends уже вышли!'
        },
        8:{'header':'поздравляем!',
            'txt': 'Ты определенно заслужил это:',
            'gift':['ВЫГОДНОЕ ПРЕДЛОЖЕНИЕ','+ХР при обновлении магазина'],
            'msg':'Мой выбор пал на Вай, перед мощью ее хекстековых перчаток не устоит никто и ничто! 🥊\n' +
                '\n' +
                'А кто из враждующих сестер симпатизирует тебе? Переходи в приложение, выполняй задания фракций и получай призы в Teamfight Tactics: яйца маленьких легенд и пропуски для нового сета!' +
                '\n' +
                '➡️ vk.com/app7978368\n' +
                '\n' +
                'Новый набор TFT: «Чудеса прогресса» и сериал «Аркейн» по вселенной League of Legends уже вышли!'
        },
    }



    function share() {
        let message = res_data[currentGame]['msg']
        bridge.send("VKWebAppShowWallPostBox",{
            "message": message,
            "attachments": "https://vk.com/app7978368",
            "link_button": "open_url",
        });
    }

    async function story() {
        let url = 'https://sun9-50.userapi.com/impg/THCgUzkZN_zEI3sVFjB-sAceB0c0WzhTFLM9bg/60Jh9-s5kiE.jpg?size=540x960&quality=96&sign=31fff3f6a657ddd361a1a1083b1fc3ee&type=album'
        if (currentGame === 1) {
            url = 'https://sun9-58.userapi.com/impg/1IIsKuqt-l0S-fosEOGEEs5fUBNbIQHmkAZthQ/tQmnMyuwGwk.jpg?size=1080x1920&quality=96&sign=d763158bb769b3adee1c4e28b1daa46c&type=album'
        } else if (currentGame === 7) {
            url = 'https://sun9-11.userapi.com/impg/7eOQ47SIwwJbRdCSbFx9w5JCJThZxuLJ1nvZLg/-BHZ8TfhKKw.jpg?size=1080x1920&quality=96&sign=ec1fdbd556bb4d357a51d21490e0254a&type=album'
        } else if (currentGame === 8) {
            url = 'https://sun9-51.userapi.com/impg/YWG7XKiqFrSMDJOwUkuW5q_4piaDrx9ceuUKtA/vQ-zkEzeH0U.jpg?size=1080x1920&quality=96&sign=59a463980c03fc27f691f81a5f4502d0&type=album'
        }
        const story = await bridge.send("VKWebAppShowStoryBox", {
            "background_type" : "image",
            "url" : url,
            "attachment": {
                "text": "open",
                "type": "url",
                "url": "https://vk.com/app7978368"
            }})
        if (story) {
            if (story.result === true) {
                const state = `state${currentGame}_story`
                let context = {[state]: true}
                ServerConnect.sendPut('/api/user/'+user.vk_id, context)
                    .then(data => dispatch(setUser(data)))
            }
        }
    }

    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <p className="caption caption_size_xl caption_align_center main-block__caption">{res_data[currentGame]['header']}</p>
                <p className="text text_size_normal text_align_center main-block__text">{res_data[currentGame]['txt']}</p>
                <GiftText txt={res_data[currentGame]['gift']}/>
                <div className="task-page__button-grid button-grid">
                    <button onClick={share}
                            className="button button_color_gradient button_size_small button-grid__button">Опубликовать <br/>
                        на стену</button>
                    <button onClick={story}
                            className="button button_color_gradient button_size_small button-grid__button">Опубликовать <br/>
                        в истории</button>
                    <button onClick={()=>dispatch(setActivePanel('menu'))}
                            className="button button_color_transparent button_size_small button-grid__button_last-button">
                        <p>
                            <span>вернуться в меню</span>
                        </p>
                    </button>

                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default Result;
