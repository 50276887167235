import React from 'react';
import Footer from "../components/Footer/Footer";
import MenuItemList from "../components/ManuItemList/MenuItemList";

const Dashboard = () => {

    return (
        <div className="container menu-page">
            <div className="menu-block container__middle-block">
                <div className="menu-block__header">
                    <p className="caption caption_size_normal menu-block__caption">Выбери объявление</p>
                </div>
                <MenuItemList/>
            </div>
            <img className="menu-page__character-pic" src="images/character-pic.png" decoding="async"
                 loading="lazy" alt=""/>
                <Footer/>
        </div>
    );
};

export default Dashboard;