import React from 'react';
import Footer from "../../components/Footer/Footer";
import Arrow from "../../components/Arrow/Arrow";
import {useDispatch} from "react-redux";
import {setActivePanel, setCurrentGame} from "../../store/mainReducer";

const AboutGame1 = () => {
    const dispatch = useDispatch()
    dispatch(setCurrentGame(1))
    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='menu'/>
                <div className="menu-item card-page__menu-item menu-item_active menu-item_size_big"
                     style={{backgroundImage: "url(images/menu-item-bg-active.png)"}}>
                    <button className="menu-item__button">
                        <img width="46" src="images/game1-icon.png" className="menu-item__icon" alt=""/>
                            <p className="menu-item__caption menu-item__caption_size_big">
                                Рекруты наемников
                            </p>
                    </button>
                </div>
                <p className="text text_size_normal text_align_center main-block__text">Наемники Зауна набирают
                    новых рекрутов! Нам подходят только отчаянные бойцы, готовые рискнуть всем.
                    Вы можете помочь, распространив информацию по своим каналам.</p>
                <button onClick={()=>dispatch(setActivePanel('result'))} className="main-block__button button button_color_gradient">Готов!</button>
            </div>
        <Footer/>
        </div>
    );
};

export default AboutGame1;