import React, {useEffect, useState} from 'react';
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Game7 = () => {

    const dispatch = useDispatch()
    const [question, setQuestion] = useState({text:'', answer1:['',null], answer2:['',null], answer3:['',null]})


    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getQuestion() {
            const num = getRandomInt(1, 6)
            let quest = data[num]
            setQuestion(quest)
        }
        getQuestion()
    }, [])


    const data = {
        1: {
            text: 'Как называется бар в Зауне?',
            answer1: ['The Last Drop', true],
            answer2: ['The Main Hub', false],
            answer3: ['The Beertover', false]
        },
        2: {
            text: 'Каким цветом накрашены ногти у Джинкс?',
            answer1: ['Розовый', false],
            answer2: ['Голубой и розовый', true],
            answer3: ['Голубой', false]
        },
        3: {
            text: 'Сколько всего кресел за столом в форме шестерни в зале заседаний Пилтовера?',
            answer1: ['3', false],
            answer2: ['5', false],
            answer3: ['7', true]
        },
        4: {
            text: 'Что протестующие бросали в городских стражников?',
            answer1: ['Помидоры', false],
            answer2: ['Гранаты', false],
            answer3: ['Коктейли Молотова', true]
        },
        5: {
            text: 'Популярный воздушный транспорт в Пилтовере?',
            answer1: ['Дирижабль', true],
            answer2: ['Вертолет', false],
            answer3: ['Гравимобиль', false]
        }
    }

    const go = (e) => {
        let btn = Number(e.currentTarget.id)
        let isRight;
        if (btn === 1) {
            isRight = question.answer1[1]
        } else if (btn === 2) {
            isRight = question.answer2[1]
        } else {
            isRight = question.answer3[1]
        }
        if (isRight) {
            dispatch(setActivePanel('game7choice'))
        } else {
            dispatch(setActivePanel('failed'))
        }
    }

    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='game7video'/>
                <div className="task-page__header">
                    <img src="images/game7g-icon.png" width="60" alt=""/>
                    <p className="caption caption_size_small">Вражда<br/>сестер</p>
                </div>

                <p className="text text_size_normal text_align_center main-block__text">{question.text}</p>
                <div className="task-page__button-grid button-grid">
                    <button id='1' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button">
                        {question.answer1[0]}
                    </button>
                    <button id='2' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button">
                        {question.answer2[0]}
                    </button>
                    <button id='3' onClick={go}
                            className="button button_color_gradient button_size_small button_text-size_small button-grid__button_last-button">
                        {question.answer3[0]}
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Game7;

