import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setNickName} from "../../store/mainReducer";

const MyInput = (props) => {
    const dispatch = useDispatch()
    let nickName = useSelector(state => state.main.nick_name)
    return (
        <div className={`input-wrapper ${props.block}-block__input-wrapper`}>
            <input className="input-wrapper__input"
                   type="text"
                   placeholder="Никнейм #Метка"
                   value={nickName}
                   onChange={(e)=> dispatch(setNickName(e.target.value))}
            />
        </div>
    );
};

export default MyInput;