import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Arrow = (props) => {
    const dispatch = useDispatch()

    return (
        <button onClick={()=> dispatch(setActivePanel(props.url))} className="container__back-button back-button">
            <img src="images/arrow.svg" alt=""/>
            <span>назад</span>
        </button>
    );
};

export default Arrow;