import React, {useEffect, useRef} from 'react';
import Arrow from "../../components/Arrow/Arrow";
import Footer from "../../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setGameVersion} from "../../store/mainReducer";

const Game5 = () => {

    const canvasRef = useRef(null)
    const dispatch = useDispatch()
    const game_ver = useSelector(state => state.main.game_version)

    useEffect(() => {
        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }
        function getVersion() {
            const num = getRandomInt(1, 4)
            dispatch(setGameVersion(num))
            return num
        }
        let n = getVersion()
        init_img(n)
        setTimeout(()=>{
            init()
        },700)
    },[])

    const bg = new Image();
    bg.src = 'images/pic-game-bg.png'

    let cells = []
    function init_img(game_ver) {
        const cell1 = new Image()
        cell1.src = `images/1_${game_ver}.jpg`
        const cell2 = new Image();
        cell2.src = `images/2_${game_ver}.jpg`
        const cell3 = new Image();
        cell3.src = `images/3_${game_ver}.jpg`
        const cell4 = new Image();
        cell4.src = `images/4_${game_ver}.jpg`
        const cell5 = new Image();
        cell5.src = `images/5_${game_ver}.jpg`
        const cell6 = new Image();
        cell6.src = `images/6_${game_ver}.jpg`
        const cell7 = new Image();
        cell7.src = `images/7_${game_ver}.jpg`
        const cell8 = new Image();
        cell8.src = `images/8_${game_ver}.jpg`
        const cell0 = new Image();
        cell0.src = 'images/0.png'

        cells = ['',cell1,cell2,cell3,cell4,cell5,cell6,cell7,cell8]
    }


    function init() {

        let canvas = canvasRef.current
        let context = canvas.getContext('2d')
        canvas.width = canvas.parentElement.offsetWidth;
        canvas.height = canvas.parentElement.offsetHeight;
        let cellSize = canvas.width / 3;

        let field = new game9(); // создаём объект пятнашек
        field.mix(170); // тщательно перемешиваем содердимое коробки
        field.setCellView(function (num, x, y) { // задаём внешний вид пятнашек
            context.drawImage(cells[num], x + 1, y + 1, cellSize - 2, cellSize - 2)
        });
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(bg, 0, 0, canvas.width, canvas.height)
        field.draw(context, cellSize);

        function event(x, y) { // функция производит необходимые действие при клике(касанию)
            field.move(x, y);
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.drawImage(bg, 0, 0, canvas.width, canvas.height)
            field.draw(context, cellSize);
            if (field.victory()) { // если головоломка сложена, то пятнашки заново перемешиваются
                dispatch(setActivePanel('game5success'))
            }
        }

        canvas.onclick = function (e) { // обрабатываем клики мышью
            let x = (e.pageX - canvas.parentElement.parentElement.offsetLeft) / cellSize | 0;
            let y = (e.pageY - canvas.parentElement.parentElement.offsetTop) / cellSize | 0;
            event(x, y); // выхов функции действия
        };
        canvas.ontouchend = function (e) { // обрабатываем касания пальцем
            let x = (e.touches[0].pageX - canvas.parentElement.parentElement.offsetLeft) / cellSize | 0;
            let y = (e.touches[0].pageY - canvas.parentElement.parentElement.offsetTop) / cellSize | 0;
            event(x, y);
        };
    }

    function game9() {
        let cellView = null;
        let arr = [[1, 2, 3], [4, 5, 6], [7, 8, 0]];

        function getNull() { // функция возвращает координату пустой клетки
            for (let i = 0; i < 3; i++) {
                for (let j = 0; j < 3; j++) {
                    if (arr[j][i] === 0) {
                        return {"x": i, "y": j};
                    }
                }
            }
        };

        // функция возвращает произвольное логическое значение
        function getRandomBool() {
            if (Math.floor(Math.random() * 2) === 0) {
                return true;
            }
        }

        // метод перемещает "пятнашку" в пустую клутку
        this.move = function (x, y) {
            let nullX = getNull().x;
            let nullY = getNull().y;
            if (((x - 1 == nullX || x + 1 == nullX) && y == nullY) || ((y - 1 == nullY || y + 1 == nullY) && x == nullX)) {
                arr[nullY][nullX] = arr[y][x];
                arr[y][x] = 0;
            }
        };
        // проверка условия победы
        this.victory = function () {
            let e = [[1, 2, 3], [4, 5, 6], [7, 8, 0]];
            let res = true;
            for (let i = 0; i < 3; i++) {
                for (let j = 0; j < 3; j++) {
                    if (e[i][j] != arr[i][j]) {
                        res = false;
                    }
                }
            }
            return res;
        };
        // метод "перемешивает" пятнашки
        this.mix = function (stepCount) {
            let x, y;
            for (let i = 0; i < stepCount; i++) {
                let nullX = getNull().x;
                let nullY = getNull().y;
                let hMove = getRandomBool();
                let upLeft = getRandomBool();
                if (!hMove && !upLeft) {
                    y = nullY;
                    x = nullX - 1;
                }
                if (hMove && !upLeft) {
                    x = nullX;
                    y = nullY + 1;
                }
                if (!hMove && upLeft) {
                    y = nullY;
                    x = nullX + 1;
                }
                if (hMove && upLeft) {
                    x = nullX;
                    y = nullY - 1;
                }
                if (0 <= x && x <= 2 && 0 <= y && y <= 2) {
                    this.move(x, y);
                }
            }
        };
        // внешний вид пятнашки
        this.setCellView = function (func) {
            cellView = func;
        };
        this.draw = function (context, size) {
            for (let i = 0; i < 3; i++) {
                for (let j = 0; j < 3; j++) {
                    if (arr[i][j] > 0) {
                        if (cellView !== null) {
                            cellView(arr[i][j], j * size, i * size);
                        }
                    }
                }
            }
        };
    }


    return (
        <div className="container task-page">
            <div className="main-block container__middle-block">
                <Arrow url='aboutgame5'/>
                <div className="task-page__header puzzle-page__header">
                    <img src="images/game5g-icon.png" width="60" alt=""/>
                    <p className="caption caption_size_small">Древний<br/>механизм</p>
                </div>
                <div className="puzzle-wrapper">
                    <img className="puzzle-wrapper__character-first" src="images/helicopter-character.png"
                         width="280" alt=""/>
                    <div className="puzzle-game">
                        <canvas ref={canvasRef}></canvas>
                    </div>
                    <img className="puzzle-wrapper__character-second" src="images/tank-character.png"
                         width="247" alt=""/>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default Game5;