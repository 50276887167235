import React, {useEffect} from 'react';
import Init from "./panels/Init";
import NickName from "./panels/NickName";
import AboutApp from "./panels/AboutApp";
import Dashboard from "./panels/Dashboard";
import AboutGame1 from "./games/Game1/AboutGame1";
import AboutGame2 from "./games/Game2/AboutGame2";
import AboutGame3 from "./games/Game3/AboutGame3";
import AboutGame4 from "./games/Game4/AboutGame4";
import AboutGame5 from "./games/Game5/AboutGame5";
import AboutGame6 from "./games/Game6/AboutGame6";
import AboutGame7 from "./games/Game7/AboutGame7";
import Game2 from "./games/Game2/Game2";
import Game3 from "./games/Game3/Game3";
import Game4 from "./games/Game4/Game4";
import Game5 from "./games/Game5/Game5";
import Game6 from "./games/Game6/Game6";
import Game7 from "./games/Game7/Game7";
import Result from "./panels/Result";
import {useDispatch, useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import {setUser, setUserExist} from "./store/mainReducer";
import ServerConnect from "./service";
import Game5Success from "./games/Game5/Game5Success";
import Game4Preload from "./games/Game4/Game4Preload";
import Game6Success from "./games/Game6/Game6Success";
import Game7Video from "./games/Game7/Game7Video";
import Game7Choice from "./games/Game7/Game7Choice";
import Failed from "./panels/Failed";

const App = () => {

    const dispatch = useDispatch()
    const activePanel = useSelector(state => state.main.activePanel)

    useEffect(() => {
        async function fetchData() {
            const user = await bridge.send('VKWebAppGetUserInfo');
            if (user) {
                return user;
            }
        }
        fetchData().then(data => get_user_exist(data))

    },[])

    async function get_user_exist (data) {
        let response = await fetch(ServerConnect.api_url + '/api/user/' + data.id);
        if (response.status === 204) {
            dispatch(setUser(data))
            dispatch(setUserExist(false))
        } else {
            let user_info = await response.json()
            dispatch(setUser(user_info))
            dispatch(setUserExist(true))
        }
    }
    let content = []

    if (activePanel === "about") {
        content.push(<AboutApp/>)
    } else if (activePanel === 'init') {
        content.push(<Init />)
    } else if (activePanel === 'login') {
        content.push(<NickName/>)
    } else if (activePanel === 'menu') {
        content.push(<Dashboard/>)
    } else if (activePanel === 'result') {
        content.push(<Result/>)
    } else if (activePanel === 'aboutgame1') {
        content.push(<AboutGame1/>)
    } else if (activePanel === 'aboutgame2') {
        content.push(<AboutGame2/>)
    } else if (activePanel === 'aboutgame3') {
        content.push(<AboutGame3/>)
    } else if (activePanel === 'aboutgame4') {
        content.push(<AboutGame4/>)
    } else if (activePanel === 'aboutgame5') {
        content.push(<AboutGame5/>)
    } else if (activePanel === 'aboutgame6') {
        content.push(<AboutGame6/>)
    } else if (activePanel === 'aboutgame7') {
        content.push(<AboutGame7/>)
    } else if (activePanel === 'game2') {
        content.push(<Game2/>)
    } else if (activePanel === 'game3') {
        content.push(<Game3/>)
    } else if (activePanel === 'game4') {
        content.push(<Game4/>)
    } else if (activePanel === 'game5') {
        content.push(<Game5/>)
    } else if (activePanel === 'game6') {
        content.push(<Game6/>)
    } else if (activePanel === 'game7') {
        content.push(<Game7/>)
    } else if (activePanel ==='game5success') {
        content.push(<Game5Success/>)
    } else if (activePanel==='game6success') {
        content.push(<Game6Success/>)
    } else if (activePanel==='game4preload') {
        content.push(<Game4Preload/>)
    } else if (activePanel==='game7video') {
        content.push(<Game7Video/>)
    } else if (activePanel==='game7choice') {
        content.push(<Game7Choice/>)
    } else if (activePanel==='failed') {
        content.push(<Failed/>)
    }

  return (
        <div className='app'>
            {content}
        </div>
  );
};

export default App;
