import React, {useEffect, useRef} from 'react';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MyInput from "../components/MyInput/MyInput";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setUser} from "../store/mainReducer";
import ServerConnect from "../service";

const NickName = () => {
    const dispatch = useDispatch()
    const user_nick = useSelector(state=>state.main.nick_name)
    const user = useSelector(state=>state.main.user)
    let button_disabled_class = (user_nick !== '') ? '' : 'button_disabled'
    let isDisabled = (user_nick !== '') ? false : true


    const setNickName = () => {
          let context = {
            vk_id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            url_photo: user.photo_max_orig,
            nick_name: user_nick
        }
        ServerConnect.sendPost('/api/user/' + user.id, context)
            .then(data => dispatch(setUser(data)))
        dispatch(setActivePanel('menu'))
    }

    return (
        <div className='container'>
            <div className="login-block container__middle-block">
                <Header block='login'>Введи<br/>RIOT ID</Header>
                <p className="text text_size_normal login-block__text">Введи свой Riot ID на RU-сервере
                    для продолжения игры и<br/>
                    получения наград. Учти, что поменять его будет нельзя.</p>
                <MyInput block='login'/>
                <button disabled={isDisabled} onClick={()=>setNickName()} className={`main-block__button button button_color_gradient ${button_disabled_class}`}>
                    К ИГРЕ!
                </button>
            </div>
            <Footer/>
        </div>

    );
};

export default NickName;